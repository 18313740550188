import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, Modal, ToggleButton, ToggleButtonGroup, Spinner } from 'react-bootstrap';
import './Payment.css';
import axios from 'axios';

const tg = window.Telegram.WebApp;

const Payment = () => {
  const [project, setProject] = useState('ChatGPT');
  const [projectId, setProjectId] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [id, setId] = useState(null);
  const [voice, setVoice] = useState(null);
  const [product, setProduct] = useState('');
  const [email, setEmail] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('yookassa');
  const [TG, setTG] = useState(false);
  const [VK, setVK] = useState(false);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Индикатор загрузки товаров

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromURL = queryParams.get('id');
    const projectFromURL = queryParams.get('project');
    if (idFromURL) {
      setVK(true);
      setId(Number(idFromURL));
    }

    if (projectFromURL) {
      if (Number(projectFromURL) === 1) {
        setProject('ChatGPT');
        setProduct('premium1');
      }
      if (Number(projectFromURL) === 2) {
        setProduct('voice1');
        setProject('CloneVoice');
      }
      setProjectId(idFromURL);
    }
  }, []);

  useEffect(() => {
    if (project === 'ChatGPT') {  
      const queryParams = new URLSearchParams(window.location.search);
      const idFromURL = queryParams.get('id');
      // Запрос товаров с сервера
      axios.post(`https://crystaldev.ru/${!idFromURL ? `chatgpt`: `chatgptvk`}`, { method: 'getCost' })
        .then(response => {
          setProducts(response.data); // Устанавливаем товары
          setProduct(`premium${response.data[0].id}`)
          setIsLoading(false); // Отключаем индикатор загрузки
        })
        .catch(error => {
          console.error('Ошибка при загрузке товаров:', error);
          setIsLoading(false); // Отключаем индикатор загрузки в случае ошибки
        });
    }
  }, [project]);

  const handleProjectChange = (val) => setProject(val);
  const handleShowContacts = () => setShowContacts(true);
  const handleCloseContacts = () => setShowContacts(false);
  const handleShowDescription = () => setShowDescription(true);
  const handleCloseDescription = () => setShowDescription(false);

  async function selectButton() {
    setProject(project === 'ChatGPT' ? 'CloneVoice' : 'ChatGPT');
    setProduct(project === 'ChatGPT' ? 'voice1' : 'premium1');
  }

  useEffect(() => {
    if (tg.initDataUnsafe.user) {
      setTG(true);
      setId(tg.initDataUnsafe?.user?.id);
    }
  }, []);

  const handleSubmit = async (e) => {
    if (id == null || product === false) return alert('Что-то не так');
    e.preventDefault();
    console.log(product)
    if(paymentMethod === `vkpay`) {
      if(product == 'premium4') {
        return tg.openLink(`https://vk.com/newchatgpt?w=donut_payment-214449286&levelId=362`);
      } else {
        return tg.openLink(`https://vk.com/newchatgpt?w=donut_payment-214449286&levelId=1615`);
      }
    }
    try {
      const response = await axios.post(`https://crystaldev.ru/${VK === true ? `chatgptvk` : `${project === 'ChatGPT' ? `chatgpt` : `clonevoice`}`}`, {
        method: 'create',
        product: product,
        id: id,
        project: project,
        email: email,
        paymentMethod: paymentMethod
      });

      if (response.data && response.data.payment_url) {
        return tg.openLink(`${response.data.payment_url}`);
      } else {
        alert(response.data?.message ? response.data?.message : 'Ошибка при создании платежа');
      }
    } catch (error) {
      console.error('Error during payment creation:', error);
      alert('Ошибка при создании платежа');
    }
  };

  return (
    <div className="payment-page">
      <Container>
        <Row className="justify-content-center">
          <Col md={6} lg={4} className="order-md-1">
            <Card className="payment-card">
              <Card.Body>
                <Card.Title className="text-center">Оплата</Card.Title>
                <Form onSubmit={handleSubmit}>
                  {projectId === false && VK === false && (
                    <Form.Group className="mb-3">
                      <Form.Label>Выберите проект</Form.Label>
                      <ToggleButtonGroup
                        type="radio"
                        name="projects"
                        value={project}
                        onChange={handleProjectChange}
                        className="w-100"
                      >
                        <ToggleButton variant="outline-primary" value="ChatGPT" onClick={selectButton} className="me-2">ChatGPT</ToggleButton>
                        <ToggleButton variant="outline-primary" value="CloneVoice" onClick={selectButton}>CloneVoice</ToggleButton>
                      </ToggleButtonGroup>
                    </Form.Group>
                  )}
                  {TG === false && VK === false && (
                    <Form.Group className="mb-3">
                      <Form.Label>Ссылка или ID</Form.Label>
                      <Form.Control type="number" min={0} placeholder="Введите ID" defaultValue={id || ''} onChange={(e) => setId(e.target.value)} required />
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Выберите товар</Form.Label>
                    {isLoading ? (
                      <Spinner animation="border" className="d-block mx-auto" />
                    ) : (
                      <Form.Control as="select" value={product} onChange={(e) => setProduct(e.target.value)}>
                        {project === 'ChatGPT' ? (
                        <>
                          {products.length > 0 ? (
                            products.map(item => (
                              <option key={item.id} value={`premium${item.id}`}>
                                {item.description}
                              </option>
                            ))
                          ) : (
                            <option>Товары не найдены</option>
                          )}
                        </>
                      ) : (
                        <>
                          <option value="voice1">🔥20 Voice - 199₽</option>
                          <option value="voice2">🔥50 Voice - 349₽</option>
                          <option value="voice3">🔥100 Voice - 599₽</option>
                          <option value="voice4">🔥300 Voice - 1499₽</option>
                        </>
                      )}
                      </Form.Control>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Выберите способ оплаты</Form.Label>
                    <Form.Control as="select" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                      <option value="yookassa">Yookassa - (РФ и СНГ) 0% комиссия</option>
                      <option value="crypto">Криптовалюта 0% комиссия</option>
                      {project === 'ChatGPT' && (
                        <>
                          {VK === true && (
                          <option value="vkpay">VKPay - (РФ и СНГ) 0% комиссия</option>                        
                          )}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                  {paymentMethod === `vkpay` && (
                    <Form.Group className="mb-3">
                      
                      Для активации подписи нажмите оплатить.
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Электронная почта (чек)</Form.Label>
                    <Form.Control type="email" placeholder="Введите почту" defaultValue={email || ''} onChange={(e) => setEmail(e.target.value)} required />
                  </Form.Group>
                  {!isLoading && <Button variant="primary" type="submit" className="w-100">Оплатить</Button>}
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="order-md-2 d-flex flex-column align-items-center justify-content-center mt-4 mt-md-0">
            <div className="button-group">
              <Button className="w-100" onClick={handleShowDescription}>Описание товаров</Button>
              <Button className="w-100" onClick={handleShowContacts}>Контакты</Button>
            </div>
            <div className="button-group">
              <Button className="w-100" href="/agreement.pdf" target="_blank">Пользовательское соглашение</Button>
              <Button className="w-100" href="https://t.me/qwizyop" target="_blank">Отменить продление</Button>   
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showContacts} onHide={handleCloseContacts}>
        <Modal.Header closeButton>
          <Modal.Title>Контакты</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='popup__inner-list'>
            <p className='popup__inner-main-title'>Связаться с нами</p>
            <p className='popup__inner-item'>Самозанятый Чертов Семён Сергеевич</p>
            <p className='popup__inner-item'>ИНН - 226702366647</p>
            <p className='popup__inner-item'>Почта: easybej1ukuu@gmail.com</p>
            <p className='popup__inner-item'>VK: https://vk.com/ez.bej1ukuu</p>
            <p className='popup__inner-item'>TG: https://t.me/bejikuu</p>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal show={showDescription} onHide={handleCloseDescription}>
        <Modal.Header closeButton>
          <Modal.Title>Описание товаров</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {project === 'ChatGPT' ? (
            <ul className='popup__inner-list'>
            <p className='popup__inner-main-title'>Авторские инструменты, шаблоны для социальных сетей и образования.</p>
            <p className='popup__inner-item'>Самая большая база шаблонов для мастеров и педагогов.</p>
            <p className='popup__inner-item'>В 2 клика прямо с тлф - создавайте  и используйте контент маркетинг (изображения и текст).</p>
            <p className='popup__inner-item'>Закрытый телеграм-канал с удобной навигацией по авторским шаблонам оформления социальных сетей и образовательных материалов.</p>
            <p className='popup__inner-main-title'>🔥Start - 149 RUB/мес (первый месяца далее 250) </p>
            <p className='popup__inner-item'>Доступ к нейро-созданию контента</p>
            <p className='popup__inner-main-title'>🔥Pro - 299 RUB/мес (первый месяца далее 500)</p>
            <p className='popup__inner-item'>Доступ к нейро-созданию контента + библиотека шаблонов</p>
            <p className='popup__inner-main-title'>🔥Platinum - 699 RUB/мес (первый месяца далее 999)</p>
            <p className='popup__inner-item'>Доступ к нейро-созданию контента + библиотека шаблонов</p>
            <p className='popup__inner-main-title'>🔥Годовой 1999 RUB/год (первый год далее 3000)</p>
            <p className='popup__inner-item'>Все наполнение тарифа "Pro" на год с выгодой 50%</p>
            </ul>
          ) : (
            <ul className='popup__inner-list'>
              <p className='popup__inner-main-title'>🔥Voice</p>
              <p className='popup__inner-item'>Доступ к нейро-созданию контента + библиотека шаблонов</p>
            </ul>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Payment;