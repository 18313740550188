import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import Home from './components/Home';
import Payment from './components/Payment';
import Info from './components/Info';
import Miniapp from './components/Miniapp';
import NotFound from './components/NotFound';
import './App.css';
import logo from './img/logo.png';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Импорт стилей AOS

// Компонент для обертки логики рендера
function Layout({ children }) {
  const location = useLocation(); // Получаем текущий путь

  return (
    <div>
      {/* Условно рендерим Navbar, если путь не равен "/miniapp" */}
      {location.pathname !== '/miniapp' && (
        <Navbar className="navbar-custom" expand="lg" data-aos="fade-down">
          <Container>
            <Navbar.Brand href="/">
              <img
                src={logo}
                alt="Logo"
                width="30"
                height="30"
                className="d-inline-block align-top"
              />
              {' '}
              Crystal Dev
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link as={Link} to="/" data-aos="fade-right">Главная</Nav.Link>
                <Nav.Link as={Link} to="/payment" data-aos="fade-right" data-aos-delay="100">Оплата</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}

      {/* Основной контент */}
      {children}

      {/* Условно рендерим footer, если путь не равен "/miniapp" */}
      {location.pathname !== '/miniapp' && (
        <footer className="bg-dark text-white text-center py-3" > {/* data-aos="fade-up"*/}
          <div>
            <p>Контакты: easybej1ukuu@gmail.com</p>
            <p>
              <a href="https://t.me/newbotchatgpt_bot" className="text-white">Telegram ChatGPT</a> |
              <a href="https://vk.com/newchatgpt" className="text-white"> VK ChatGPT</a> |
              <a href="https://t.me/CloneVoiceAi_bot" className="text-white"> Telegram VoiceClone</a>
            </p>
          </div>
        </footer>
      )}
    </div>
  );
}

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // продолжительность анимации
      once: true, // анимация происходит один раз
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (document.querySelector('.navbar-collapse.show')) {
        document.querySelector('.navbar-toggler').click();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.navbar-collapse') && document.querySelector('.navbar-collapse.show')) {
        document.querySelector('.navbar-toggler').click(); // Закрытие при клике вне меню
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/info" element={<Info />} />
          <Route path="/miniapp" element={<Miniapp />} />
          <Route path="*" element={<NotFound />} /> {/* Обработка несуществующих маршрутов */}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
